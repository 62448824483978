<div class="rvt-container-lg">
  <h2 class="rvt-p-tb-sm rvt-m-bottom-none">Contact Us</h2>
  <div class="contact-section">
    <div class="contact-section-content">
      <div>
        <p>For support or any questions:</p>
        <p>
          Email us at: <a href="mailto:jsomLIVE@iu.edu">jsomLIVE@iu.edu</a>
        </p>
      </div>
      <div>
        <h4>Mailing Address</h4>
        <p>
          200 S. Eagleson Avenue <br />
          Room C-039 <br />
          Bloomington, IN 47405 <br />
          United States <br />
        </p>
      </div>
    </div>
  </div>
</div>
